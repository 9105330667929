<template>
  <div class="container">
    <div class="header"><span class="line"></span> 结算报表</div>
    <div class="nav">
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="5" :md="8" :sm="12">
            <a-form-item label="付款单号" :labelCol="{lg: {span: 10}, sm: {span: 7}}" :wrapperCol="{lg: {span: 14}, sm: {span: 17} }">
              <a-input v-model="queryParam.relationPayId" placeholder="请输入付款单号"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="detail(record)">查看详情</a>
        </span>
        <span slot="avator" v-if="record.headUrl" slot-scope="text, record">
          <img :src="record.headUrl" style="width:30px;height:30px" alt="">
        </span>
        <span slot="payType" slot-scope="text, record">
          <img v-if="record.payType==='30'" src="../../assets/zhifu_pay.png" style="width:30px;height:30px" alt="">
          <img v-if="record.payType==='20'" src="../../assets/weixin_pay.png" style="width:30px;height:30px" alt="">
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="发起付款"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item :label="item.serviceName" v-for="(item,index) in selectedRows" :key="index">
          <a-input placeholder="请输入付款金额" v-model="item.settlementAmount"/>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 详情 -->
    <a-modal title="详情" width="100%" v-if="visibleDetailModal" class="detail-modal" :footer="null" :visible="visibleDetailModal" @cancel="visibleDetailModal = false;">
      <Detail v-if="visibleDetailModal" :id="currentId"></Detail>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import Detail from './components/selltementDetail';
import {
  merchantServiceSettlement,
  createSettlement
} from '@/api/api';
export default {
  components: {
    STable,
    Detail
  },
  data () {
    return {
      raskColumns: [
        {
          title: '付款单号',
          align: 'center',
          dataIndex: 'relationPayId',
        },
        {
          title: '结算金额',
          align: 'center',
          dataIndex: 'settlementAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '支付时间',
          align: 'center',
          dataIndex: 'payTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus',
          align: 'center',
          customRender: text=> text==='10'?'待支付':text==='11'?'支付中':text==='20'?'支付成功':'支付失败'
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'createTime',
          customRender: text => text===null?'--':text
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      currentId: '',
      visibleDetailModal: false,
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        relationPayId: '',
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        id: undefined
      },
      date: [],
      selectedRows: []
    }
  },
  methods: {
    // 选择时间
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    // 查看详情
    detail (record) {
      this.currentId = record
      this.visibleDetailModal = true
    },
    // 获取列表
    raskData (parameter) {
      return merchantServiceSettlement(Object.assign(this.queryParam,parameter)).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        relationPayId: '',
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        id: undefined,
      };
      this.date = [];
      this.handleToSearchEnterprise();
    },
    // 发起付款
    handleOkAdd (e) {
      let allowAdd = true
      this.selectedRows.forEach(item=>{
        if (item.settlementAmount==='') {
          allowAdd = false
          this.$message.error(`${item.serviceName}付款金额不能为空`)
        }
      })
      if (!allowAdd) {
        return
      }
      this.confirmLoadingAdd = true
      createSettlement({models: this.selectedRows}).then(res=> {
        this.confirmLoadingAdd = false
        if (res.success) {
          this.$message.success(res.message)
          this.handleToSearchEnterprise()
          this.handleCancelAdd()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleCancelAdd () {
      this.confirmLoadingAdd = false
      this.visibleAdd = false
    },
    // 发起付款
    surePay () {
      this.visibleAdd = true
    }
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>
<style lang="less">
.detail-modal {
  top: 0;
  height: 100vh;
  .ant-modal-content {
    height: 100vh;
    margin-top: -100px;
    .ant-modal-body {
      height: calc(100%-55px);
      padding: 0;
    }
  }
}
</style>