<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <!-- <a-button type="primary" @click="handleToSearchScope">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchScope">重置</a-button> -->
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record, index) => index"
      :columns="scopeColumns"
      :data="loadScopeList"
      bordered
    >
      <span slot="avator" v-if="record.headUrl" slot-scope="text, record">
        <img :src="record.headUrl" style="width:30px;height:30px" alt="">
      </span>
      <span slot="payType" slot-scope="text, record">
        <img v-if="record.payType==='30'" src="../../../assets/zhifu_pay.png" style="width:30px;height:30px" alt="">
        <img v-if="record.payType==='20'" src="../../../assets/weixin_pay.png" style="width:30px;height:30px" alt="">
      </span>
    </s-table>
    <!-- 添加 -->
    <a-modal title="添加店铺" :visible="addModal" :confirm-loading="confirmLoadingAdd" @ok="handleScopeModalOk" @cancel="handleScopeModalCancel">
      <a-form :form="addScopeForm" class="config-form">
        <a-form-item label="店铺" :labelCol="{lg: {span: 4}, sm: {span: 7}}" :wrapperCol="{lg: {span: 20}, sm: {span: 17} }">
          <a-select style="width: 100%" placeholder="请输入并选择店铺" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompany" mode="multiple" @change="handleChangeSelectCompany" v-decorator="['merchantIds', {rules:[{ required: true, message: '请输入并选择店铺', type: 'array' }]}]">
            <a-select-option v-for="item in scopeRateList" :key="item.id" :value="item.id">{{item.fullName}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-card>
</template>
<script>
import {
  merchantServiceSettlementDetail,
  merchantStaffConfig,
  merchantStaffConfigDel,
  enterpriseMerchantList
} from '@/api/api'
import STable from '@/components/table/';
export default {
  components: {
    STable
  },
  data () {
    return {
      addScopeForm: this.$form.createForm(this),
      addModal: false,
      searchParams: {
        status:undefined
      },
      scopeColumns: [
        {
          title: '关联付款单号',
          dataIndex: 'relationPayId',
          align: 'center'
        },
        {
          title: '用户名称',
          align: 'center',
          dataIndex: 'userName',
        },
        {
          title: '支付金额',
          align: 'center',
          dataIndex: 'settlementAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '支付时间',
          align: 'center',
          dataIndex: 'payTime',
          customRender: text => text===null?'--':text
        },
      ],
      scopeRateList: [],
      confirmLoadingAdd: false
    }
  },
  props: {
    id: {
      type: Object,
      required: false
    }
  },
  methods: {
    // 获取列表数据
    loadScopeList(parameter) {
      return merchantServiceSettlementDetail(Object.assign(parameter, {mainId: this.id.id})).then(res => {
        return res.result
      })
    },
    // 查询
    handleToSearchScope() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchScope() {
      this.searchParams = {
        status: ''
      };
      this.handleToSearchScope();
    },
    // 选择门店（多选）
    handleChangeSelectCompany (value) {
    },
    handleSearchSelectCompany (value) {
      enterpriseMerchantList({fullName:value}).then(res =>{
        if (res.success) {
          this.scopeRateList = res.result.data;
        }
      })
    },
    // 删除
    Del (record) {
      let that = this
      this.$confirm({
        title: '确定删除?',
        onOk() {
          return new Promise((resolve, reject) => {
            merchantStaffConfigDel({id:record.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.handleToSearchScope()
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 新增
    handleModalVisible() {
      this.addModal = true;
    },
    handleScopeModalOk() {
      this.addScopeForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        this.confirmLoadingAdd = true
        const description = this.addScopeForm.getFieldsValue();
        merchantStaffConfig(Object.assign(description, {
          staffId: this.id.userId,
          staffLoginId: this.id.userLoginId
        })).then((res) => {
          this.confirmLoadingAdd = false
          if (res.success) {
            this.handleToSearchScope();
            this.$message.success(res.message);
            this.addModal = false;
          } else {
            this.$message.error(res.message);
          }
        }).catch(()=>{this.confirmLoadingAdd = false})
      });
    },
    handleScopeModalCancel() {
      this.addModal = false;
      this.addScopeForm.resetFields();
    },
  }
}
</script>
<style scoped>
.table-page-search-wrapper{
  margin: 0 0 20px 0;
}
</style>